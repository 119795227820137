
import "../node_modules/leaflet/dist/leaflet.css";
import './Map.css';
import React, { Component } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

class Map extends Component {
    state = {};

    componentDidMount() {
        console.log('Mounted')
    }

    render() {
        return (
            <div className="MapContainer">
                <MapContainer center={[40, -50]} zoom={3}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </MapContainer> 
            </div>  
        )
    };
}

export default Map;